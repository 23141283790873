import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import { css } from "@emotion/core"
import { Box } from "reflexbox"
import BackgroundImage from "gatsby-background-image"

import Layout from "../components/common/Layout"

import { Heading, Text } from "~/components/common/Typography"
import Button from "~/components/common/Button"

const Page = () => {
  const data = useStaticQuery(graphql`
    query {
      landing: file(name: { eq: "LandingPage" }) {
        childImageSharp {
          fluid(maxWidth: 1440) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  const { landing } = data
  return (
    <Layout
      header={{ hideBurger: false, hideProgress: true }}
      overflow={"hidden"}
      // welcome={false}
    >
      <BackgroundImage
        Tag="section"
        loading={"eager"}
        fluid={landing.childImageSharp.fluid}
        backgroundColor={`#333333`}
        css={p => css`
          /* Sizing */
          width: 100vw;
          height: 100vh;

          /* Background styles */
          background-size: cover;
          background-position: 60% center;
          background-repeat: no-repeat;
          background-attachment: fixed;
          overflow: hidden;
        `}
      >
        <Box
          as="section"
          maxWidth={"100%"}
          mx={"auto"}
          flexDirection="column"
          justifyContent="start"
          textAlign="center"
          px={["16px", "32px"]}
          pt={7}
          pb={5}
          height={"100%"}
          css={css`
            overflow: scroll;
          `}
        >
          <Box maxWidth={"desktop"} mx={"auto"} mb={"150px"}>
            <Box
              maxWidth={["100%", "100%", "490px"]}
              mx={["auto", "auto", 0]}
              bg="grey"
              p="6"
            >
              <Box width={"90%"} mx={"auto"}>
                <Heading
                  fontFamily={"AdineuePROBlackWeb"}
                  background={"yellow"}
                  fontSize={["32px", "48px"]}
                  lineHeight={["40px", "66px"]}
                >
                  Keep Girls in Sport
                </Heading>
              </Box>

              <Text
                mt="5"
                uppercase
                fontSize={["16px", "20px"]}
                lineHeight={["22px", "24px"]}
                fontFamily={"AdineuePROBlackWeb"}
                color={"black"}
                css={css`
                  text-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
                `}
              >
                A digital curriculum for coaches, mentors, and role models for
                girls.
              </Text>

              <Text
                mt="5"
                fontSize={["16px"]}
                lineHeight={["24px"]}
                color={"black"}
                css={css`
                  text-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
                `}
              >
                Through sport, we have the power to change girls’ lives.
              </Text>
              <Text
                mt="5"
                fontSize={["16px"]}
                lineHeight={["24px"]}
                color={"black"}
                css={css`
                  text-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
                `}
              >
                adidas and Up2Us Sports believe in the importance of coaches to
                unlock all that sports have to offer girls, which in turn will
                improve those girls’ lives and better society. The female
                athletes of today will be the leaders of tomorrow, and
                supporting them in realizing their full potential will change
                the fate of generations to come.
              </Text>
              <Link
                to={"/introduction-why-it-matters/"}
                css={css`
                  text-decoration: none;
                  &:hover {
                    text-decoration: none;
                  }
                `}
              >
                <Button
                  mt="5"
                  height="64px"
                  minHeight="64px"
                  fontSize={["20px"]}
                  lineHeight={["24px"]}
                  width={["100%", "400px"]}
                  mx={"auto"}
                >
                  Start Course
                </Button>
              </Link>

              <a
                href={"/about/"}
                target={"_blank"}
                css={css`
                  text-decoration: none;

                  &:hover {
                    text-decoration: none;
                    //text-decoration-color: black;
                  }
                `}
              >
                <Button
                  mt="5"
                  height="64px"
                  minHeight="64px"
                  fontSize={["20px"]}
                  lineHeight={["24px"]}
                  width={["100%", "400px"]}
                  mx={"auto"}
                  bg={"transparent"}
                  css={css`
                    color: black;
                    border: 1px black solid;
                  `}
                >
                  LEARN MORE
                </Button>
              </a>
            </Box>
          </Box>
        </Box>
      </BackgroundImage>
    </Layout>
  )
}

export default Page
